import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Link, NavLink } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import CTA from "../CTA";
import Footer from "../Footer";
import { motion } from "framer-motion";
import RateScrolling from "../ReuseableComponent/RateScrolling";

const FADE_INTERVAL_MS = 1750;
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2;
const WORDS_TO_ANIMATE = ["Easier", "Faster", "Simpler"];

type FadeProp = { fade: "fade-in" | "fade-out" };

function NewHome() {
  const [openTestTab, setOpenTestTab] = useState(1);
  const [fadeProp, setFadeProp] = useState<FadeProp>({ fade: "fade-in" });
  const [wordOrder, setWordOrder] = useState(0);

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === "fade-in"
        ? setFadeProp({ fade: "fade-out" })
        : setFadeProp({ fade: "fade-in" });
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [fadeProp]);

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder(
        (prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length
      );
    }, WORD_CHANGE_INTERVAL_MS);

    return () => clearInterval(wordTimeout);
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [twoisHovered, setTwoIsHovered] = useState(false);
  const [girlisHovered, setGirlIsHovered] = useState(false);

  const images = [{ url: "/images/hero3.jpg" }, { url: "/images/hero1.png" }];
  return (
    <>
      <section className=" bg-gradient-to-r from-[#FFFCFC] to-[#DBEEE6]">
        <Navbar />
        {/* <RateScrolling /> */}
        <div className="pt-[120px] pb-5">
          <div className="grid  px-6 lg:px-16  pb-8  lg:gap-8 xl:gap-0 lg:pt-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-[30px] text-[#191A15] font-bold md:leading-[90px] tracking-normal  md:text-[80px] xl:text-[80px]">
                Educational <br />
                One-stop-shop <br />
                made {/* <span className='text-[#209D5A]'>Easier</span> */}
                <span className={fadeProp.fade + " text-[#209D5A]"}>
                  {WORDS_TO_ANIMATE[wordOrder]}
                </span>
              </h1>
              <div>
                <img src="/images/arc.svg" alt="hero" />
              </div>

              <p className="  mb-6 mt-3 font-normal text-black lg:mb-8 lg:text-base text-[12px] break-all">
                {" "}
                Your Educational Hero
              </p>
              {/* <p className="lg:block hidden max-w-[520px]  mb-6 mt-3 font-normal text-black lg:mb-8 lg:text-base text-[12px] break-all">
                AfriPay aims to revolutionize the EdTech payment landscape by providing an integrated payment solution for students and their <br />families, simplifying international tuition fee payments.
              </p>
              <p className="block lg:hidden   mb-6 mt-3 font-normal text-black lg:mb-8  text-[12px] break-all">
                AfriPay aims to revolutionize the EdTech payment landscape by providing an integrated payment solution for students and their families, simplifying international tuition fee payments.
              </p> */}

              <div className="flex ">
                <NavLink to="/sign-in" className="">
                  <button
                    type="submit"
                    className="lg:py-[14px] lg:px-[30px] px-[20px] py-[9px] text-[16px] font-normal text-white bg-[#209D5A] rounded-[40px] border border-[#209D5A] "
                  >
                    Get Started
                  </button>
                </NavLink>
                {/* <NavLink to="/">
                  <button
                    type="button"
                    className="ml-5 lg:py-[8px] lg:px-[18px] px-[10px] py-[4px]  text-[16px] font-normal text-[#209D5A] bg-[#fff] rounded-[40px] border border-[#209D5A] text-center inline-flex items-center"
                  >
                    <svg
                      width="34"
                      height="34"
                      className=" mr-2 "
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.0001 1.16667C25.7432 1.16667 32.8334 8.25525 32.8334 17C32.8334 25.7448 25.7432 32.8333 17.0001 32.8333C8.25533 32.8333 1.16675 25.7448 1.16675 17C1.16675 8.25525 8.25533 1.16667 17.0001 1.16667Z"
                        stroke="#209D5A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.0001 16.9919C22.0001 15.64 15.071 11.3152 14.2849 12.0928C13.4989 12.8705 13.4233 21.0401 14.2849 21.891C15.1465 22.7449 22.0001 18.3438 22.0001 16.9919Z"
                        stroke="#209D5A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    View Demo
                  </button>
                </NavLink> */}
              </div>
            </div>
            <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full md:mt-0 mt-[50px]">
              {/* <img src="/images/hero.png" alt="hero" /> */}

              <div className="lg:block hidden">
                <img
                  src={
                    isHovered ? "/images/hero.png" : "/images/hero anime.gif"
                  }
                  alt="Image"
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                  className="w-full h-auto"
                />
              </div>
              <div className="block lg:hidden">
                <img
                  src={"/images/hero anime.gif"
                  }
                  alt="Image"
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                  className="w-full h-auto"
                />
              </div>

              {/* <div className="px-6">
              <SimpleImageSlider
                width={550}
                height={560}
                images={images}
                showBullets={false}
                showNavs={false}
                autoPlay={true}
              />
            </div> */}
              {/* <div className="" id="gallary">
              <img
                src="/images/images/hero3.jpg"
                alt="hero"
                className=" opacity-0 w-full"
              />
            </div> */}
            </div>
          </div>
          {/* <div className='flex justify-center text-center mt-0 md:mt-6'>
            <h2 className='max-w-[940px] md:text-[32px] text-[20px] text-[#191A15] font-bold'>Assisting global school payments, making education transactions seamless</h2>
          </div> */}
        </div>
      </section>

      {/* <-- All your education savings in one place --> */}
      <section className="hidden lg:block">
        <div className="pt-10 md:pt-10 px-6">
          <div className="flex justify-center text-center mt-0 md:mt-6 ">
            <h2 className="max-w-[940px] md:text-[40px] text-[18px] text-[#000000] font-bold">
              All your Education Savings
              <br /> in One Place
            </h2>
          </div>
          <div className="mt-3 flex justify-center lg:gap-6 gap-3">
            <span className="border border-[#000000] rounded-[20px] lg:text-[16px] text-[10px] font-normal lg:px-[32px] px-[10px] py-[8px] ">
              Save as you like
            </span>
            <span className="border border-[#000000] rounded-[20px] lg:text-[16px] text-[10px] font-normal lg:px-[32px] px-[10px] py-[8px]">
              Fixed deposit
            </span>
            <span className="border border-[#000000] rounded-[20px] lg:text-[16px] text-[10px] font-normal lg:px-[32px] px-[10px] py-[8px]">
              Invest in your future
            </span>
          </div>
          <div className="flex justify-center lg:pt-20 pt-10 mx-6">

            <div className="">
              <img
                src={
                  girlisHovered
                    ? "/images/girl-card.png"
                    : "/images/girl-card anime.gif"
                }
                alt="Image"
                onMouseOver={() => setGirlIsHovered(true)}
                onMouseOut={() => setGirlIsHovered(false)}
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <-- All in one stop shop for students --> */}
      <section className="pt-10 md:pt-10 pb-10 bg-gradient-to-r from-[#EDEDED] to-[#EDF2FE]">
        <div className=" px-8 lg:px-16">
          <div className="flex flex-col justify-center text-center mt-0 md:mt-6">
            <h2 className=" md:text-[40px] text-[18px] text-[#000000] font-bold text-center">
              All in one stop shop for students
            </h2>
            <p className="text-[#373E4B] lg:text-[15px] text-[12px]">
              Be at ease because we’ve got you
              <br />
              covered when it comes to your Education
            </p>
          </div>

          <div className="lg:mx-[100px] lg:grid lg:grid-cols-3 grid-cols-1 gap-5 lg:pt-5 pt-5">
            <div className="col-span-1">
              <div className="bg-white lg:rounded-[30px] rounded-[15px] lg:px-[28px] px-[10px] lg:pt-[40px] pt-[20px] lg:pb-[65px] pb-[20px]">
                <div className="flex">
                  <div>
                    <img src="/images/card.png" alt="hero" />
                  </div>
                  <div className="mt-[65px]">
                    <img src="/images/hand.png" alt="hero" />
                  </div>
                  <div>
                    <img src="/images/passport.png" alt="hero" />
                  </div>
                </div>
                <div className="flex justify-center mb-1">
                  <img
                    src="/images/shop.png"
                    alt="hero"
                    className="lg:block hidden"
                  />
                  <img
                    src="/images/shop.png"
                    alt="hero"
                    className="lg:hidden block w-[50%]"
                  />
                </div>

                <h3 className="text-[#373E4B] lg:text-[25px] text-[18px]">
                  Your All-in-One Education Solution

                </h3>
                <p className="text-[#373E4B] lg:text-[15px] text-[10px] mt-2">
                  Relax and let us take care of all your educational needs.
                </p>
                <Link to="/afripayshop">
                  <div className="flex mt-4">
                    <h3 className="lg:text-[16px] text-[12px] text-[#252628] font-medium mt-1 lg:mt-0">
                      Learn More
                    </h3>
                    <svg
                      width="34"
                      height="26"
                      viewBox="0 0 34 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1327_500)">
                        <path
                          d="M21.8652 2.74609C16.903 2.74609 12.8809 6.76822 12.8809 11.7305C12.8809 16.6927 16.903 20.7148 21.8652 20.7148C26.8275 20.7148 30.8496 16.6927 30.8496 11.7305C30.8439 6.77109 26.8239 2.75184 21.8652 2.74609ZM21.8652 19.9961C17.3005 19.9961 13.5996 16.2952 13.5996 11.7305C13.5996 7.16569 17.3005 3.46484 21.8652 3.46484C26.43 3.46484 30.1309 7.16569 30.1309 11.7305C30.1258 16.2931 26.4271 19.9911 21.8652 19.9961ZM24.2759 11.476C24.3413 11.5414 24.3816 11.6313 24.3816 11.7305C24.3816 11.8297 24.3413 11.9195 24.2759 11.9849L20.6822 15.5787C20.6168 15.6441 20.5269 15.6843 20.4277 15.6843C20.2294 15.6843 20.0684 15.5233 20.0684 15.3249C20.0684 15.2257 20.1086 15.1359 20.174 15.0705L23.5133 11.7312L20.174 8.39187C20.1086 8.32647 20.0684 8.23663 20.0684 8.13744C20.0684 7.93906 20.2294 7.77806 20.4277 7.77806C20.5269 7.77806 20.6168 7.81831 20.6822 7.88372L24.2759 11.476Z"
                          fill="#252628"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1327_500">
                          <rect
                            width="23"
                            height="23"
                            fill="white"
                            transform="translate(10.3652 0.230469)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-span-2 lg:mt-0 mt-[20px]">
              <div className="bg-gradient-to-r from-[#1B814A] to-[#184F3B] lg:rounded-[30px] rounded-[15px] lg:px-[30px] px-[10px] lg:pt-[20px] py-[20px]">
                <div className="hidden lg:block">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-white lg:text-[25px] text-[15px] font-semibold">
                        Flight and Accommodations Booking
                      </h3>
                      <p className="text-white mt-1">
                        Book flights and accommodations with AfriPay
                      </p>
                      <Link to="/afripayshop">
                        <div className="flex mt-2">
                          <h3 className="lg:text-[16px] text-[12px] text-[#fff] font-medium mt-1 lg:mt-0">
                            Learn More
                          </h3>
                          <svg
                            width="34"
                            height="25"
                            viewBox="0 0 34 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1327_515)">
                              <path
                                d="M22.2202 2.76562C17.258 2.76562 13.2358 6.78775 13.2358 11.75C13.2358 16.7122 17.258 20.7344 22.2202 20.7344C27.1825 20.7344 31.2046 16.7122 31.2046 11.75C31.1988 6.79063 27.1789 2.77138 22.2202 2.76562ZM22.2202 20.0156C17.6554 20.0156 13.9546 16.3148 13.9546 11.75C13.9546 7.18522 17.6554 3.48438 22.2202 3.48438C26.785 3.48438 30.4858 7.18522 30.4858 11.75C30.4808 16.3126 26.7821 20.0106 22.2202 20.0156ZM24.6309 11.4956C24.6963 11.561 24.7366 11.6508 24.7366 11.75C24.7366 11.8492 24.6963 11.939 24.6309 12.0044L21.0372 15.5982C20.9717 15.6636 20.8819 15.7038 20.7827 15.7038C20.5843 15.7038 20.4233 15.5428 20.4233 15.3445C20.4233 15.2453 20.4636 15.1554 20.529 15.09L23.8683 11.7507L20.529 8.41141C20.4636 8.346 20.4233 8.25616 20.4233 8.15697C20.4233 7.95859 20.5843 7.79759 20.7827 7.79759C20.8819 7.79759 20.9717 7.83784 21.0372 7.90325L24.6309 11.4956Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1327_515">
                                <rect
                                  width="23"
                                  height="23"
                                  fill="white"
                                  transform="translate(10.7202 0.25)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="image-wrapper plane-wrapper">
          <img src="/images/plane1.png" alt="hero" className="image plane" />

          </div> */}
                  </div>
                  {/* <div className="image-wrapper house-wrapper">
        <img src="/images/house1.png" alt="hero" className="image house" />

        </div> */}

                  <div className="image-container">
                    <img src="/images/house1.png" alt="hero" className=" absolute  image plane" />
                    <img src="/images/plane1.png" alt="hero" className="absolute bottom-[150px] left-0 image  house" />


                  </div>
                </div>

                <div className="lg:hidden block">
                  <div>
                    <div>
                      <h3 className="text-white text-[15px] font-semibold">
                        Flight and Accommodations Booking
                      </h3>
                      <p className="text-white text-[12px] mt-1">
                        Book flights and accommodations with AfriPay
                      </p>
                    </div>
                    {/* <div className="flex justify-between mt-4">
            <div className="image-wrapper plane-wrapper">
              <img
                src="/images/plane1.png"
                alt="plane"
                className="w-[50%]"
              />
            </div>
            <div className="image-wrapper house-wrapper">
              <img
                src="/images/house1.png"
                alt="house"
                className="w-[50%]"
              />
            </div>
          </div> */}
                    <Link to="/afripayshop">
                      <div className="flex mt-2">
                        <h3 className="lg:text-[16px] text-[12px] text-[#fff] font-medium mt-1 lg:mt-0">
                          Learn More
                        </h3>
                        <svg
                          width="34"
                          height="25"
                          viewBox="0 0 34 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1327_515)">
                            <path
                              d="M22.2202 2.76562C17.258 2.76562 13.2358 6.78775 13.2358 11.75C13.2358 16.7122 17.258 20.7344 22.2202 20.7344C27.1825 20.7344 31.2046 16.7122 31.2046 11.75C31.1988 6.79063 27.1789 2.77138 22.2202 2.76562ZM22.2202 20.0156C17.6554 20.0156 13.9546 16.3148 13.9546 11.75C13.9546 7.18522 17.6554 3.48438 22.2202 3.48438C26.785 3.48438 30.4858 7.18522 30.4858 11.75C30.4808 16.3126 26.7821 20.0106 22.2202 20.0156ZM24.6309 11.4956C24.6963 11.561 24.7366 11.6508 24.7366 11.75C24.7366 11.8492 24.6963 11.939 24.6309 12.0044L21.0372 15.5982C20.9717 15.6636 20.8819 15.7038 20.7827 15.7038C20.5843 15.7038 20.4233 15.5428 20.4233 15.3445C20.4233 15.2453 20.4636 15.1554 20.529 15.09L23.8683 11.7507L20.529 8.41141C20.4636 8.346 20.4233 8.25616 20.4233 8.15697C20.4233 7.95859 20.5843 7.79759 20.7827 7.79759C20.8819 7.79759 20.9717 7.83784 21.0372 7.90325L24.6309 11.4956Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1327_515">
                              <rect
                                width="23"
                                height="23"
                                fill="white"
                                transform="translate(10.7202 0.25)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </Link>
                  </div>
                  <div className="image-container">
                    <img src="/images/house1.png" alt="hero" className=" absolute  image plane" />
                    <img src="/images/plane1.png" alt="hero" className="absolute bottom-[100px] -left-[120px] image  house" />


                  </div>
                </div>

              </div>
              <div className="mt-[25px] grid lg:grid-cols-2 grid-cols-1 gap-3">
                {/* <div className='bg-gradient-to-br from-[#209D5A] to-[#327D55] rounded-[30px] px-[40px] pt-[20px] pb-[10px]'>
                  <h3 className='text-white text-[25px] font-semibold'>Proof of Funds</h3>
                  <Link to="/product">
                    <div className='flex mt-2'>
                      <h3 className='text-[16px] text-[#fff] font-medium'>Learn More</h3>
                      <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1327_515)">
                          <path d="M22.2202 2.76562C17.258 2.76562 13.2358 6.78775 13.2358 11.75C13.2358 16.7122 17.258 20.7344 22.2202 20.7344C27.1825 20.7344 31.2046 16.7122 31.2046 11.75C31.1988 6.79063 27.1789 2.77138 22.2202 2.76562ZM22.2202 20.0156C17.6554 20.0156 13.9546 16.3148 13.9546 11.75C13.9546 7.18522 17.6554 3.48438 22.2202 3.48438C26.785 3.48438 30.4858 7.18522 30.4858 11.75C30.4808 16.3126 26.7821 20.0106 22.2202 20.0156ZM24.6309 11.4956C24.6963 11.561 24.7366 11.6508 24.7366 11.75C24.7366 11.8492 24.6963 11.939 24.6309 12.0044L21.0372 15.5982C20.9717 15.6636 20.8819 15.7038 20.7827 15.7038C20.5843 15.7038 20.4233 15.5428 20.4233 15.3445C20.4233 15.2453 20.4636 15.1554 20.529 15.09L23.8683 11.7507L20.529 8.41141C20.4636 8.346 20.4233 8.25616 20.4233 8.15697C20.4233 7.95859 20.5843 7.79759 20.7827 7.79759C20.8819 7.79759 20.9717 7.83784 21.0372 7.90325L24.6309 11.4956Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1327_515">
                            <rect width="23" height="23" fill="white" transform="translate(10.7202 0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </Link>

                  <motion.div
                    initial={{ x: 0 }}
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 1.3 },
                    }}

                    className='mt-[50px]'>
                    <img src="/images/pof-bag.png" alt="hero" />
                  </motion.div>
                </div> */}
                <div className="bg-white lg:rounded-[30px] rounded-[15px] lg:px-[30px] px-[10px] py-[10px] lg:pt-[20px] ">
                  <h3 className="text-[#36383B] lg:text-[25px] text-[18px] font-semibold">
                    Study Loans
                  </h3>
                  <p className="text-[#36383B] lg:text[15px] text-[12px] mt-1">
                    We offer financial assistance for programs at over 1,000 of the finest educational institutions globally.
                  </p>
                  <Link to="/loan">
                    <div className="flex mt-2">
                      <h3 className="lg:text-[16px] text-[12px] text-[#252628] font-medium mt-1 lg:mt-0">
                        Learn More
                      </h3>
                      <svg
                        width="34"
                        height="26"
                        viewBox="0 0 34 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1327_500)">
                          <path
                            d="M21.8652 2.74609C16.903 2.74609 12.8809 6.76822 12.8809 11.7305C12.8809 16.6927 16.903 20.7148 21.8652 20.7148C26.8275 20.7148 30.8496 16.6927 30.8496 11.7305C30.8439 6.77109 26.8239 2.75184 21.8652 2.74609ZM21.8652 19.9961C17.3005 19.9961 13.5996 16.2952 13.5996 11.7305C13.5996 7.16569 17.3005 3.46484 21.8652 3.46484C26.43 3.46484 30.1309 7.16569 30.1309 11.7305C30.1258 16.2931 26.4271 19.9911 21.8652 19.9961ZM24.2759 11.476C24.3413 11.5414 24.3816 11.6313 24.3816 11.7305C24.3816 11.8297 24.3413 11.9195 24.2759 11.9849L20.6822 15.5787C20.6168 15.6441 20.5269 15.6843 20.4277 15.6843C20.2294 15.6843 20.0684 15.5233 20.0684 15.3249C20.0684 15.2257 20.1086 15.1359 20.174 15.0705L23.5133 11.7312L20.174 8.39187C20.1086 8.32647 20.0684 8.23663 20.0684 8.13744C20.0684 7.93906 20.2294 7.77806 20.4277 7.77806C20.5269 7.77806 20.6168 7.81831 20.6822 7.88372L24.2759 11.476Z"
                            fill="#252628"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1327_500">
                            <rect
                              width="23"
                              height="23"
                              fill="white"
                              transform="translate(10.3652 0.230469)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </Link>

                  <motion.div
                    // initial={{ x: 0 }}
                    whileHover={{
                      scale: 1.3,
                      transition: { duration: 1.3 },
                    }}
                    className="lg:ml-[100px]"
                  >
                    <img
                      src="/images/loan-png.png"
                      alt="hero"
                      className="hidden lg:block w-[70%]"
                    />
                    <img
                      src="/images/loan-png.png"
                      alt="hero"
                      className="lg:hidden block w-[50%]"
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- Start block --> */}

      <section className="bg-[#EFF8F6] pt-6">
        <div className="py-5 ">
          <h3 className="lg:text-[24px] text-[14px] font-bold text-center">
            Featured by
          </h3>
          <div className="flex space-x-4 md:space-x-8 items-center justify-center px-6">
            <div className='mt-6'>
              <img src="/images/master.png" alt="hero" />
            </div>
            <div className='mt-6'>
              <img src="/images/light.png" alt="hero" />
            </div>
          </div>
          <hr className="mt-6" />
        </div>
        <div className="pb-3 ">
          <h3 className="lg:text-[24px] text-[14px] font-bold text-center">
            Our partners
          </h3>
          <div className="flex space-x-4 md:space-x-8 items-center justify-center px-6">
            <div className="">
              <img src="/images/wema.png" alt="hero" />
            </div>
            {/* <div className="mt-6">
                <img src="/images/interswitch.png" alt="hero" />
              </div> */}
            <div className="">
              <img src="/images/flutterwave.png" className="w-[250px]" alt="hero" />
            </div>
            <div className="">
              <img src="/images/verto.png" className="w-[140px]" alt="hero" />
            </div>
            {/* <div className='mt-6'>
                                <img src="/images/mpower.png" alt="hero" />
                            </div> */}

          </div>
        </div>
      </section>

      <div className=" flex-col px-8 py-10 lg:px-16">

        {/* bg-gradient-to-br from-[#1C613C] from-90% to-[#0A8661] to-10% */}
        {/* <section className="bg-gradient-to-br from-[#1C613C] from-90% to-[#0A8661] to-10% lg:rounded-[30px] rounded-[15px] lg:py-[20px] py-[10px]">
        <div className=" lg:block">
          <div className="flex justify-center md:pt-6">
            <div>
              <h5 className=" hidden lg:block text-[32px] text-white text-center font-[600]  leading-7 ">
                Our Trusted Partners
              </h5>
              <h5 className=" lg:hidden block text-white text-center text-[18px] pb-3  font-semibold leading-7 ">
                Our Trusted Partners
              </h5>
              <div className="flex space-x-4 md:space-x-8 items-center justify-center px-6">
                <div>
                <img
                src="./images/mastercard-large-white-1.png"
                className="w-[200px] md:h-[90px]"
                alt="Afripro Logo"
              />
                </div>
             
              <div className="md:mt-7">
                <img src="/images/wema.png" alt="hero" />
              </div>
              <div className="mt-6">
                <img src="/images/interswitch.png" alt="hero" />
              </div>
              <div className="md:mt-6">
                <img src="/images/flutterwave.png" className="w-[250px]" alt="hero" />
              </div>
              <div className="md:mt-6">
                <img src="/images/verto.png" className="w-[140px]" alt="hero" />
              </div>
              </div>
             
            </div>
          </div>
       
        </div>

        
      </section> */}
      </div>
      {/* <!-- End block --> */}


      {/* <-- Benefits of using Afripay --> */}
      <section className="pt-10 md:pt-20 pb-10 bg-gradient-to-br from-[#FFF7F7] to-[#DAF1E8]">
        <div className="flex md:flex-row flex-col px-8 lg:px-16">
          <div className="flex-1 flex justify-center items-start flex-col">
            <div className="md:flex flex-col w-full">
              <h1 className="hidden lg:block flex-1 md:text-[50px]  font-bold text-[#191A15] md:leading-[58px]">
                Benefits of Using
                <br /> AfriPay
              </h1>
              <h1 className=" lg:hidden block flex-1  text-[20px] font-bold text-[#191A15] md:leading-[58px]">
                Benefits of Using AfriPay
              </h1>
            </div>
            <div className="lg:mt-[44px] mt-[20px]">
              <div className="flex space-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="#1db459">
                    <path
                      d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z"
                      opacity=".5"
                    />
                    <path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" />
                  </g>
                </svg>
                <h3 className="mt-1 font-medium text-[#191A15] text-[14px] md:text-[18px]">
                  Simplified Payments
                </h3>
              </div>
              <div className="flex space-x-2 mt-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="#1db459">
                    <path
                      d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z"
                      opacity=".5"
                    />
                    <path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" />
                  </g>
                </svg>
                <h3 className="mt-1 font-medium text-[#191A15] text-[14px] md:text-[18px]">
                  Secure Transactions
                </h3>
              </div>
              <div className="flex space-x-2 mt-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="#1db459">
                    <path
                      d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z"
                      opacity=".5"
                    />
                    <path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" />
                  </g>
                </svg>
                <h3 className="mt-1 font-medium text-[#191A15] text-[14px] md:text-[18px]">
                  Multiple Payment Options
                </h3>
              </div>
              <div className="flex space-x-2 mt-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="#1db459">
                    <path
                      d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z"
                      opacity=".5"
                    />
                    <path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" />
                  </g>
                </svg>
                <h3 className="mt-1 font-medium text-[#191A15] text-[14px] md:text-[18px]">
                  Effortless Currency Conversion
                </h3>
              </div>
              <div className="flex space-x-2 mt-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="#1db459">
                    <path
                      d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z"
                      opacity=".5"
                    />
                    <path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" />
                  </g>
                </svg>
                <h3 className="mt-1 font-medium text-[#191A15] text-[14px] md:text-[18px]">
                  Responsive Customer Support
                </h3>
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center md:mt-0 mt-[50px]">
            {/* <img src="/images/img1.png" alt="hero" /> */}
            <div className="lg:block hidden">
              <img
                src={
                  twoisHovered ? "/images/img1 anime.gif" : "/images/img1.png"
                }
                alt="Image"
                onMouseOver={() => setTwoIsHovered(true)}
                onMouseOut={() => setTwoIsHovered(false)}
                className="w-full h-auto"
              />
            </div>
            <div className="block lg:hidden">
              <img
                src={"/images/img1 anime.gif"
                }
                alt="Image"
                onMouseOver={() => setTwoIsHovered(true)}
                onMouseOut={() => setTwoIsHovered(false)}
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <-- Get Started --> */}
      <section className="pt-10 md:pt-20 pb-10 bg-gradient-to-tr from-[#FFFFFF] to-[#DAF1E8]">
        <div className=" flex-col px-8 lg:px-16">
          <div className="bg-gradient-to-br from-[#1C613C] from-90% to-[#0A8661] to-10% lg:rounded-[30px] rounded-[15px] lg:p-[60px] p-[20px]">
            <div className="flex lg:flex-row flex-col  lg:justify-between">
              <h3 className="lg:text-[45px] text-[20px] text-white font-semibold">
                Fulfill various
                academic hurdles<br /> in minutes.
              </h3>
              <div className="mt-3">
                <NavLink to="/sign-in">
                  <button className="bg-[#01A067] text-white rounded-[30px] inline-flex py-[15px] px-[40px]">
                    {/* Chat with us */}
                    Get Started
                    <svg
                      width="31"
                      height="24"
                      viewBox="0 0 31 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1288_5719)">
                        <path
                          d="M28.1772 10.4419L22.5522 16.0669C22.4391 16.18 22.2829 16.25 22.1104 16.25C21.7654 16.25 21.4854 15.97 21.4854 15.625C21.4854 15.4525 21.5554 15.2962 21.6685 15.1831L26.2266 10.625H13.9854C13.6404 10.625 13.3604 10.345 13.3604 10C13.3604 9.655 13.6404 9.375 13.9854 9.375H26.2266L21.6685 4.81687C21.5554 4.70375 21.4854 4.5475 21.4854 4.375C21.4854 4.03 21.7654 3.75 22.1104 3.75C22.2829 3.75 22.4391 3.82 22.5522 3.93313L28.1772 9.55813C28.2904 9.67125 28.3604 9.8275 28.3604 10C28.3604 10.1725 28.2904 10.3288 28.1772 10.4419Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1288_5719">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(10.8604)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="flex lg:flex-row  lg:justify-between flex-col mt-10">
              <div className="lg:pt-10 pt-3">
                <div className="flex lg:space-x-6 space-x-3">
                  <span className=" items-center justify-center bg-[#0167A0] text-white lg:w-[45px] lg:h-[45px] w-14 h-8 lg:rounded-[45px] rounded-full text-center lg:text-[22px] text-[18px] font-[200]">
                    1
                  </span>
                  <div>
                    <h3 className="text-white lg:text-[20px] text-[15px] font-semibold">
                      Create Account
                    </h3>
                    <p className="lg:w-[287px] text-white lg:text-[15px] text-[12px] lg:mt-2">
                      Sign up for an account with your email and password in a
                      few steps.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-6 mt-[20px]">
                  <span className="items-center justify-center bg-[#0167A0] text-white lg:w-[45px] lg:h-[45px]  w-14 h-8 lg:rounded-[45px] rounded-full text-center lg:text-[22px] text-[18px] font-[200]">
                    2
                  </span>
                  <div>
                    <h3 className="text-white lg:text-[20px] text-[15px] font-semibold">
                      Choose your preferred service
                    </h3>
                    <p className="lg:w-[287px] text-white lg:text-[15px] text-[12px] lg:mt-2">
                      Make loan requests from your unique dashboard after
                      filling our KYC form.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-6 mt-[20px]">
                  <span className="items-center justify-center bg-[#0167A0] text-white lg:w-[45px] lg:h-[45px]  w-14 h-8 lg:rounded-[45px] rounded-full text-center lg:text-[22px] text-[18px] font-[200]">
                    3
                  </span>
                  <div>
                    <h3 className="text-white lg:text-[20px] text-[15px] font-semibold">
                      Make Payment
                    </h3>
                    <p className="lg:w-[287px] text-white lg:text-[15px] text-[12px] lg:mt-2">
                      Once you’re verified, funds will be disbursed to your bank
                      account, easy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:mt-0 mt-10">
                <img src="/images/dashboard.png" alt="hero" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <-- What you can do with Afripay --> */}
      <section className="pt-10 md:pt-20 pb-10 bg-[#F9F8FE] ">
        <div className="grid lg:grid-cols-12 lg:gap-5  px-8 lg:px-16">
          <div className="  lg:col-span-6">
            <h1 className="md:text-[50px] text-[20px] font-bold text-[#191A15] md:leading-[58px]">
              What you can do with AfriPay
            </h1>
            <p className="max-w-[640px] lg:mt-[30px] mt-3 text-[12px] md:text-[16px] text-[#A6A6A6]">
              AfriPay empowers your education journey. Seamlessly handle tuition
              payments, secure educational loans, and simplify proof of funds
              for a stress-free study abroad experience.
            </p>
          </div>
          <div className="mt-[50px] md:mt-0  lg:col-span-6">
            <div className="flex space-x-3 ">
              <div>
                <img src="/images/tuition-icon.png" alt="hero" />
              </div>
              <div>
                <h3 className="text-[#191A15] text-[18px] md:text-[28px] font-bold">
                  Tuition Payment
                </h3>
                <p className="max-w-[420px] text-[#A6A6A6] text-[14px] md:text-[18px]">
                  Simplify international tuition payments with AfriPay. Quick,
                  secure transactions to ensure a hassle-free academic journey
                  for students and families.
                </p>
              </div>
            </div>

            <div className="flex space-x-3 mt-[30px]">
              <div>
                <img src="/images/loan-icon.png" alt="hero" />
              </div>
              <div>
                <h3 className="text-[#191A15] text-[18px] md:text-[28px] font-bold">
                  Loans
                </h3>
                <p className="max-w-[420px] text-[#A6A6A6] text-[14px] md:text-[18px]">
                  Empower your educational aspirations with AfriPay's easy loan
                  access. Your path to success begins with a click.
                </p>
              </div>
            </div>
            <div className="flex space-x-3 mt-[30px]">
              <div>
                <img src="/images/pof-icon.png" alt="hero" />
              </div>
              <div>
                <h3 className="text-[#191A15] text-[18px] md:text-[28px] font-bold">
                  Savings
                </h3>
                <p className="max-w-[420px] text-[#A6A6A6] text-[14px] md:text-[18px]">
                  Unlock your financial goals with AfriPay's effortless savings
                  solutions. Start your journey to financial security with just
                  a click.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <-- Something else is needed here --> */}
      {/* <section className='pt-10 md:pt-20 pb-10 bg-gradient-to-br from-[#FFFCFC] to-[#F2FAF7]'>
        <div className='flex flex-col justify-center text-center'>
          <h1 className="flex-1 md:text-[50px] text-[30px] font-bold text-[#191A15] md:leading-[58px]">Something Else is<br /> needed here </h1>
          <p className="mt-6 font-medium text-[#A6A6A6] text-[16px] md:text-[18px]">I will add something here or better still run it </p>
          <div className='mt-[40px]'>
            <div className="md:mx-auto mx-6 xl:max-w-[340px] rounded-[10px] text-sm font-medium text-center bg-white p-2 flex ">
              <div
                onClick={() => setOpenTestTab(1)}
                className={` ${openTestTab === 1 ? "bg-[#209D5A] text-[#ffffff] rounded-[10px]" : ""}  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}>Option</div>
              <div
                onClick={() => setOpenTestTab(2)}
                className={` ${openTestTab === 2 ? "bg-[#209D5A] text-[#ffffff] rounded-[10px]" : ""}  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}>Option </div>

            </div>
          </div>
        </div>
      </section> */}

      {/* <-- CTA --> */}
      {/* <CTA /> */}

      {/* <-- Footer --> */}
      <Footer />
    </>
  );
}

export default NewHome;
